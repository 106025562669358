.custom-swiper-pagination-1 {
    @apply z-[10] absolute text-center bottom-[8.333vw] sm:bottom-[2.083vw] !important;
}

.custom-swiper-pagination-contentbygrade {
    @apply z-[100] absolute text-center top-0 grid grid-cols-3 gap-x-[2.292vw] gap-y-[3.611vw] sm:gap-y-[1.146vw] w-[91.111vw] sm:w-[34.583vw] h-[7.604vw] left-[50%] -translate-x-1/2 !important;
}

.custom-swiper-pagination-mainbanner {
    @apply z-[10] absolute text-center bottom-[15.278vw] sm:bottom-[2.604vw] !important;
}

.custom-bullet {
    /* 기본 bullet 스타일 */
    @apply bg-[#C3D6FF] w-[3.333vw] sm:w-[0.833vw] h-[3.333vw] sm:h-[0.833vw] opacity-100 mx-[2.5vw] sm:mx-[0.521vw] !important;
    /* `!important`를 사용하여 우선순위 높임 */
}

.custom-bullet-active {
    /* 활성화된 bullet 스타일 */
    @apply bg-[#5C6DFF] w-[6.667vw] sm:w-[1.667vw] rounded-[13.889vw] sm:rounded-[1.302vw] opacity-100 mx-[0.521vw] !important;
    /* `!important`를 사용하여 우선순위 높임 */
}

.custom-bullet-contentbygrade {
    @apply flex justify-center items-center bg-[#2046E4] w-[28.333vw] sm:w-[10vw] h-[10vw] sm:h-[3.229vw] font-[pretendard-sb] text-[4.722vw] sm:text-[1.354vw] text-[#ffffff] rounded-full opacity-100 mx-0 box-border !important;
}

.custom-bullet-active-contentbygrade {
    @apply bg-[initial] text-[#2046E4] border-[0.556vw] sm:border-[0.156vw] border-[#2046E4] opacity-100 mx-0 !important;
}

/* custom style */

/* react-transition-group css */
.home-content-1-1-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-1-1-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-1-2-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-1-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-1-2-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-1-2-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-2-1-enter {
    @apply opacity-0 scale-50;
}

.home-content-2-1-enter-active {
    @apply opacity-100 scale-100 transition duration-700;
}

.home-content-2-1-exit {
    @apply opacity-100 scale-100;
}

.home-content-2-1-exit-active {
    @apply opacity-0 scale-50 transition duration-300;
}

.home-content-2-2-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-2-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-2-2-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-2-2-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-2-3-enter {
    @apply opacity-0;
}

.home-content-2-3-enter-active {
    @apply opacity-100 transition duration-700;
}

.home-content-2-3-exit {
    @apply opacity-100;
}

.home-content-2-3-exit-active {
    @apply opacity-0 transition duration-300;
}

.home-content-2-4-enter {
    @apply opacity-0 translate-x-[40px];
}

.home-content-2-4-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700;
}

.home-content-2-4-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-2-4-exit-active {
    @apply opacity-0 translate-x-[40px] transition duration-300;
}

.home-content-2-5-enter {
    @apply opacity-0 translate-x-[-40px];
}

.home-content-2-5-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700;
}

.home-content-2-5-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-2-5-exit-active {
    @apply opacity-0 translate-x-[-40px] transition duration-300;
}

.home-content-2-6-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-2-6-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-2-6-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-2-6-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-2-7-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-2-7-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-[400ms];
}

.home-content-2-7-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-2-7-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-3-1-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-3-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-3-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-3-1-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-3-2-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-3-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-3-2-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-3-2-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-4-1-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-4-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-4-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-4-1-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-4-2-enter {
    @apply opacity-0 translate-y-[40px];
}

.home-content-4-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-4-2-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-4-2-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.home-content-4-3-enter {
    @apply opacity-0 translate-y-[50px] sm:translate-y-[100px] -translate-x-[100px] sm:-translate-x-[300px];
}

.home-content-4-3-enter-active {
    @apply opacity-100 translate-y-0 translate-x-0 transition duration-700 delay-200;
}

.home-content-4-3-exit {
    @apply opacity-100 translate-y-0 translate-x-0;
}

.home-content-4-3-exit-active {
    @apply opacity-0 translate-y-[50px] sm:translate-y-[100px] -translate-x-[100px] sm:-translate-x-[300px] transition duration-300;
}

.home-content-4-4-enter {
    @apply opacity-0;
}

.home-content-4-4-enter-active {
    @apply opacity-100 transition duration-700 delay-[400ms];
}

.home-content-4-4-exit {
    @apply opacity-100;
}

.home-content-4-4-exit-active {
    @apply opacity-0 transition duration-300;
}

.home-content-5-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-5-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-5-1-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-5-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-5-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-5-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-5-2-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-5-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-6-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-6-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-6-1-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-6-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-7-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-7-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-7-1-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-7-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-7-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-7-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-7-2-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-7-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-7-3-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-7-3-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-[400ms];
}

.home-content-7-3-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-7-3-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-8-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-8-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-8-1-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-8-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-8-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-8-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-8-2-exit {
    @apply opacity-100 translate-y-0 ;
}

.home-content-8-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-9-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-9-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-9-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-9-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-9-2-enter {
    @apply opacity-0 translate-x-[100px];
}

.home-content-9-2-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700 delay-200;
}

.home-content-9-2-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-9-2-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.home-content-9-3-enter {
    @apply opacity-0 translate-x-[100px];
}

.home-content-9-3-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700 delay-[400ms];
}

.home-content-9-3-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-9-3-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.home-content-9-4-enter {
    @apply opacity-0 translate-x-[100px];
}

.home-content-9-4-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700 delay-[600ms];
}

.home-content-9-4-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-9-4-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.home-content-10-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-10-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-10-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-10-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-11-1-enter {
    @apply opacity-0 scale-50;
}

.home-content-11-1-enter-active {
    @apply opacity-100 scale-100 transition duration-700;
}

.home-content-11-1-exit {
    @apply opacity-100 scale-100;
}

.home-content-11-1-exit-active {
    @apply opacity-0 scale-50 transition duration-300;
}

.home-content-11-2-enter {
    @apply opacity-0 translate-x-[-100px];
}

.home-content-11-2-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700 delay-200;
}

.home-content-11-2-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-11-2-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}

.home-content-11-3-enter {
    @apply opacity-0 translate-x-[100px];
}

.home-content-11-3-enter-active {
    @apply opacity-100 translate-x-0 transition duration-700 delay-200;
}

.home-content-11-3-exit {
    @apply opacity-100 translate-x-0;
}

.home-content-11-3-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.home-content-11-4-enter {
    @apply opacity-0 translate-y-[60px];
}

.home-content-11-4-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-11-4-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-11-4-exit-active {
    @apply opacity-0 translate-y-[60px] transition duration-300;
}

.home-content-12-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-12-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.home-content-12-1-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-12-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-12-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-12-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.home-content-12-2-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-12-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.home-content-12-3-enter {
    @apply opacity-0 translate-y-[100px];
}

.home-content-12-3-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-[400ms];
}

.home-content-12-3-exit {
    @apply opacity-100 translate-y-0;
}

.home-content-12-3-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}