/*react-transition-group css*/
.curriculum-content-1-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-1-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-1-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-1-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.curriculum-content-1-2-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-1-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-1-3-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-1-3-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-[400ms];
}

.curriculum-content-1-3-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-1-3-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-2-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-2-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-2-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-2-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-2-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-2-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.curriculum-content-2-2-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-2-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-1-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-1-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-2-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-2-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-2-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-2-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-3-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-3-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-3-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-3-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-4-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-4-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-4-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-4-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-5-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-5-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-5-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-5-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-3-6-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-3-6-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-3-6-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-3-6-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-4-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-4-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-300;
}

.curriculum-content-4-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-4-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-8-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-8-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.curriculum-content-8-1-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-8-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.curriculum-content-8-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.curriculum-content-8-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.curriculum-content-8-2-exit {
    @apply opacity-100 translate-y-0;
}

.curriculum-content-8-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.mobile-curriculum-content-2-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.mobile-curriculum-content-2-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.mobile-curriculum-content-2-1-exit {
    @apply opacity-100 translate-y-0;
}

.mobile-curriculum-content-2-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.mobile-curriculum-content-3-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.mobile-curriculum-content-3-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.mobile-curriculum-content-3-1-exit {
    @apply opacity-100 translate-y-0;
}

.mobile-curriculum-content-3-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.mobile-curriculum-content-4-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.mobile-curriculum-content-4-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.mobile-curriculum-content-4-1-exit {
    @apply opacity-100 translate-y-0;
}

.mobile-curriculum-content-4-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}
