/* react-transition-group css */
.faq-1-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.faq-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.faq-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.faq-1-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.faq-1-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.faq-1-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.faq-1-2-exit {
    @apply opacity-100 translate-y-0;
}

.faq-1-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}