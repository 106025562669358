@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #ffffff;
    box-sizing: border-box;
    /* 화면 하단에서 상단으로 이동 시 부드럽게 이동 */
    scroll-behavior: smooth;
}

body {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "nanum-square-round-r";
}

.scrollable-div {
    overflow-y: auto; /* 수직 스크롤 가능 */
    scrollbar-width: none; /* Firefox 용 */
    -ms-overflow-style: none; /* Internet Explorer 10+ 용 */

    /* Chrome, Safari, Opera 등의 브라우저용 */
    &::-webkit-scrollbar {
        display: none;
    }
}

.fade-out {
    opacity: 0;
    transition: opacity 300ms ease;
}

.fade-in {
    opacity: 1;
    transition: opacity 300ms ease;
}

@font-face {
    font-family: "nanum-square-round-l";
    src: url("./resource/font/nanum_square_round/NanumSquareRoundL.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-square-round-r";
    src: url("./resource/font/nanum_square_round/NanumSquareRoundR.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-square-round-b";
    src: url("./resource/font/nanum_square_round/NanumSquareRoundB.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-square-round-eb";
    src: url("./resource/font/nanum_square_round/NanumSquareRoundEB.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-t";
    src: url("./resource/font/pretendard/Pretendard-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-el";
    src: url("./resource/font/pretendard/Pretendard-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-l";
    src: url("./resource/font/pretendard/Pretendard-Light.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-r";
    src: url("./resource/font/pretendard/Pretendard-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-m";
    src: url("./resource/font/pretendard/Pretendard-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-sb";
    src: url("./resource/font/pretendard/Pretendard-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-b";
    src: url("./resource/font/pretendard/Pretendard-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-eb";
    src: url("./resource/font/pretendard/Pretendard-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "pretendard-black";
    src: url("./resource/font/pretendard/Pretendard-Black.ttf") format("truetype");
}

@font-face {
    font-family: "wanjudaedunsanche-r";
    src: url("./resource/font/wanjudaedunsanche/wanjudaedunsanche-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "wanjudaedunsanche-b";
    src: url("./resource/font/wanjudaedunsanche/wanjudaedunsanche-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-barun-gothic-ul";
    src: url("./resource/font/nanum_barun_gothic/NanumBarunGothicUltraLight.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-barun-gothic-l";
    src: url("./resource/font/nanum_barun_gothic/NanumBarunGothicLight.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-barun-gothic-r";
    src: url("./resource/font/nanum_barun_gothic/NanumBarunGothic.ttf") format("truetype");
}

@font-face {
    font-family: "nanum-barun-gothic-b";
    src: url("./resource/font/nanum_barun_gothic/NanumBarunGothicBold.ttf") format("truetype");
}

@font-face {
    font-family: "fa-brands-400";
    src: url("./resource/font/font-awesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
    font-family: "fa-regular-400";
    src: url("./resource/font/font-awesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: "fa-solid-400";
    src: url("./resource/font/font-awesome/fa-solid-900.ttf") format("truetype");
}

.indicator {
    cursor: pointer;
    width: 12px;
    height: 12px;
    text-align: center;
    background-color: #dfe8fc;
    border-radius: 9999px;
    margin: 0;
    margin-right: 10px;
    margin-left: 10px;
}

.indicator.active {
    background: #3d70ee;
}

.indicator2 {
    cursor: pointer;
    width: 12px;
    height: 12px;
    text-align: center;
    background-color: #b5bbc8;
    border-radius: 9999px;
    margin: 0;
    margin-right: 10px;
    margin-left: 10px;
}

.indicator2.active {
    background: #ffffff;
}


