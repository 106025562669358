/* swiper custom */
.custom-bullet-contentbymode {
    @apply flex justify-center items-center bg-[#DDEAFF] w-[28.333vw] sm:w-[8.75vw] h-[8.333vw] sm:h-[2.708vw] font-[pretendard-sb] text-[#9CAFC5] text-[4.722vw] sm:text-[1.354vw] rounded-full opacity-100 mx-0 box-border !important;
}

.custom-bullet-active-contentbymode {
    @apply bg-[#5C6DFF] text-[#ffffff] opacity-100 mx-0 !important;
}

.custom-swiper-pagination-contentbymode {
    @apply z-[-1] absolute text-center top-0 grid grid-cols-3 gap-x-[3.056vw] sm:gap-x-[2.292vw] gap-y-[22px] w-[91.111vw] sm:w-[30.833vw] left-[50%] -translate-x-1/2 !important;
}

/* react-transition-group css */
.content-1-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.content-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.content-1-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-1-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-1-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.content-1-2-exit {
    @apply opacity-100 translate-y-0;
}

.content-1-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-1-3-enter {
    @apply opacity-0 translate-x-[100px];
}

.content-1-3-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-1-3-exit {
    @apply opacity-100 translate-x-0;
}

.content-1-3-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.content-2-1-enter {
    @apply opacity-0 translate-x-[-100px];
}

.content-2-1-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000;
}

.content-2-1-exit {
    @apply opacity-100 translate-x-0;
}

.content-2-1-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}

.content-3-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-3-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.content-3-1-exit {
    @apply opacity-100 translate-y-0;
}

.content-3-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-3-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-3-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.content-3-2-exit {
    @apply opacity-100 translate-y-0;
}

.content-3-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-3-3-enter {
    @apply opacity-0 translate-x-[-100px];
}

.content-3-3-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-3-3-exit {
    @apply opacity-100 translate-x-0;
}

.content-3-3-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}

.content-3-4-enter {
    @apply opacity-0 translate-x-[100px];
}

.content-3-4-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-3-4-exit {
    @apply opacity-100 translate-x-0;
}

.content-3-4-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.content-4-1-enter {
    @apply opacity-0 translate-x-[100px];
}

.content-4-1-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000;
}

.content-4-1-exit {
    @apply opacity-100 translate-x-0;
}

.content-4-1-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.content-4-2-enter {
    @apply opacity-0 translate-x-[-100px];
}

.content-4-2-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000;
}

.content-4-2-exit {
    @apply opacity-100 translate-x-0;
}

.content-4-2-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}

.content-5-1-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-5-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700;
}

.content-5-1-exit {
    @apply opacity-100 translate-y-0;
}

.content-5-1-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-5-2-enter {
    @apply opacity-0 translate-y-[100px];
}

.content-5-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-700 delay-200;
}

.content-5-2-exit {
    @apply opacity-100 translate-y-0;
}

.content-5-2-exit-active {
    @apply opacity-0 translate-y-[100px] transition duration-300;
}

.content-5-3-enter {
    @apply opacity-0 translate-x-[100px];
}

.content-5-3-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-5-3-exit {
    @apply opacity-100 translate-x-0;
}

.content-5-3-exit-active {
    @apply opacity-0 translate-x-[100px] transition duration-300;
}

.content-5-4-enter {
    @apply opacity-0 translate-x-[-100px];
}

.content-5-4-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-5-4-exit {
    @apply opacity-100 translate-x-0;
}

.content-5-4-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}

.content-5-5-enter {
    @apply opacity-0 translate-x-[-100px];
}

.content-5-5-enter-active {
    @apply opacity-100 translate-x-0 transition duration-1000 delay-[400ms];
}

.content-5-5-exit {
    @apply opacity-100 translate-x-0;
}

.content-5-5-exit-active {
    @apply opacity-0 translate-x-[-100px] transition duration-300;
}