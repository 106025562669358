/* react-transition-group css */
.aicourseware-content-1-1-enter {
    @apply opacity-0 translate-y-[40px];
}

.aicourseware-content-1-1-enter-active {
    @apply opacity-100 translate-y-0 transition duration-300;
}

.aicourseware-content-1-1-exit {
    @apply opacity-100 translate-y-0;
}

.aicourseware-content-1-1-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.aicourseware-content-1-2-enter {
    @apply opacity-0 translate-y-[40px];
}

.aicourseware-content-1-2-enter-active {
    @apply opacity-100 translate-y-0 transition duration-300;
}

.aicourseware-content-1-2-exit {
    @apply opacity-100 translate-y-0;
}

.aicourseware-content-1-2-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.aicourseware-content-1-3-enter {
    @apply opacity-0 translate-y-[40px];
}

.aicourseware-content-1-3-enter-active {
    @apply opacity-100 translate-y-0 transition duration-300 delay-[200ms];
}

.aicourseware-content-1-3-exit {
    @apply opacity-100 translate-y-0;
}

.aicourseware-content-1-3-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.aicourseware-content-1-4-enter {
    @apply opacity-0 translate-y-[40px];
}

.aicourseware-content-1-4-enter-active {
    @apply opacity-100 translate-y-0 transition duration-300 delay-[400ms];
}

.aicourseware-content-1-4-exit {
    @apply opacity-100 translate-y-0;
}

.aicourseware-content-1-4-exit-active {
    @apply opacity-0 translate-y-[40px] transition duration-300;
}

.fade-enter {
    @apply opacity-0;
}

.fade-enter-active {
    @apply opacity-100 transition duration-300;
}

.fade-exit {
    @apply opacity-100;
}

.fade-exit-active {
    @apply opacity-0 transition duration-300;
}
