/* custom */
.top-bar {
    width: 100%;
    height: 13.889vw;
    box-shadow: 0 0.556vw 0.278vw 0 rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: #ffffff;
    z-index: 10;
}

/* 미디어 쿼리 */
@media (min-width: 640px) {
    .top-bar {
        height: 7.083vw;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }
}

/*react-transition-group css*/
.top-bar-enter {
    @apply translate-y-[-13.889vw] sm:translate-y-[-7.083vw];
}

.top-bar-enter-active {
    @apply translate-y-0 transition duration-300;
}

.top-bar-exit {
    @apply translate-y-0;
}

.top-bar-exit-active {
    @apply translate-y-[-13.889vw] sm:translate-y-[-7.083vw] transition duration-300;
}

.menu-enter {
    @apply translate-y-[-100vh];
}

.menu-enter-active {
    @apply translate-y-0 transition duration-300;
}

.menu-exit {
    @apply translate-y-0 opacity-100;
}

.menu-exit-active {
    @apply translate-y-[-100vh] opacity-0 transition duration-300;
}